@import url("https://fonts.cdnfonts.com/css/xolonium");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
}

body {
	font-family: "Xolonium", sans-serif;
}

.scrollingEffect {
  animation: scrollEffect 20s linear infinite;
}

@keyframes scrollEffect {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

svg {
  display: inline-block;
  vertical-align: middle;
}

path {
  fill: #2a354d;
}
